<template>
  <div class="root">
    <div class="critere_container text-center">
      <div class="feuille"><img src="../assets/feuille.svg" /></div>
      <h1 class="main_title">{{ lang.critere }} {{ cnum }}<small>/5</small></h1>

      <div class="subtitle my-2 my-md-4">
        {{ lang.critere_subtitle }}<br /><strong
          >&laquo;{{ lang["critere" + cnum + "_name"] }}&raquo;</strong
        >
      </div>

      <helpBanner></helpBanner>

      <div class="form row step">
        <div class="col-md-8 mx-auto">
          <div
            class="form-check"
            v-for="i in 5"
            :key="i"
            @click="critere1 = i"
            :class="{ current: critere1 == i }"
          >
            <input
              class="form-check-input"
              type="radio"
              :name="'critere' + cnum"
              :id="'critere' + cnum + '-' + i"
              :value="i"
              v-model="critere1"
            />
            <label
              class="form-check-label"
              :for="'critere' + cnum + '-' + i"
              v-html="lang['c' + cnum + 'l' + i]"
            ></label>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-bar">
      <div class="container">
        <div class="row">
          <div class="stepper col-12">
            <div
              class="step_marker"
              v-for="i in 6"
              :key="i"
              :class="{ current: i == cnum }"
            ></div>
          </div>
        </div>

        <div class="row justify-content-md-center">
          <div class="col-md-4 col-6">
            <router-link
              to="/comment-ca-marche"
              class="btn btn-secondary d-block"
              >{{ lang.precedent }}</router-link
            >
          </div>
          <div class="col-md-4 col-6">
            <router-link
              to="/critere2"
              class="btn btn-primary d-block"
              v-bind:class="{ 'disabled btn-light': !critere1 }"
              >{{ lang.suivant }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "critere1",
  data() {
    return {
      cnum: 1,
    };
  },
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
    critere1: {
      set(value) {
        this.$store.commit("setCritere1", { critere1: value });
      },
      get() {
        return this.$store.state.critere1;
      },
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="scss">
</style>